import axios from "axios";
import { useEffect } from "react";
import { useState } from "react";
import FormNav from "../formNav/FormNav";
import "./remittanceform.scss";
import ReCaptchaV2 from "react-google-recaptcha";
import env from "react-dotenv";

function RemittanceForm() {
  const [remittanceId, setRemittanceId] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [error, setError] = useState({});
  const [loading, setLoading] = useState(false);
  const [amount, setAmount] = useState("");
  const [remitDetails, setremitDetails] = useState({});
  const [formToken, setFormToken] = useState("");

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  const validate = () => {
    let remittanceIdError = "";
    let tokenError = "";

    if (!remittanceId) {
      remittanceIdError = "Remittance Id is required";
    }

    if (!formToken.token) {
      tokenError =
        "ReCaptcha is required, note!!! refresh page if ReCaptcha is not showing";
    }

    if (remittanceIdError || tokenError) {
      setError((currError) => {
        return { ...currError, remittanceId: remittanceIdError };
      });
      setError((currError) => {
        return { ...currError, token: tokenError };
      });

      return false;
    }
    return true;
  };

  const handleToken = (token) => {
    setFormToken((currentForm) => {
      return { ...currentForm, token };
    });
  };

  const handleExpire = () => {
    setFormToken((currentForm) => {
      return { ...currentForm, token: null };
    });
  };

  const formatNo = (amount) => {
    const stringCov = JSON.stringify(amount);

    const covertAmount =
      stringCov === ""
        ? "0"
        : parseFloat(stringCov).toLocaleString("en-US", {
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
          });
    setAmount(covertAmount);
  };

  const handleFirstSubmit = async (e) => {
    e.preventDefault();
    const checkValidate = validate();
    if (checkValidate) {
      setLoading(true);
      try {
        const res = await axios.post("/remittance", { remittanceId });
        setLoading(false);
        if (res.data.success) {
          setremitDetails(res.data);
          setRemittanceId("");
          formatNo(res.data.amount);
        } else {
          setErrorMessage(res.data.message);
          setTimeout(() => {
            setErrorMessage("");
          }, 8000);
        }
      } catch (err) {
        setErrorMessage("Something went wrong try again later!!!");
        setTimeout(() => {
          setErrorMessage("");
        }, 8000);
        setLoading(false);
      }
    }
  };

  return (
    <div className="form__remit__con">
      <FormNav />
      <div className="form__remit">
        <h1 className="form__remit__header"> Remitance Payment </h1>
        <div className="form__remit__inputWrapper">
          <form onSubmit={handleFirstSubmit}>
            <div className="form__remit__inputCon">
              <label htmlFor="remittance">Remittance ID</label>
              <div className="form__remit__inputCon__remittance">
                <input
                  style={{
                    border: !error.remittanceId
                      ? "1px solid black"
                      : "1px solid var(--error-color)",
                  }}
                  id="remittance"
                  onChange={(e) => {
                    setRemittanceId(e.target.value);
                    if (error.remittanceId) {
                      setError({});
                    }
                  }}
                  type="text"
                  value={remittanceId}
                />

                <button type="submit">
                  {" "}
                  {loading ? "Submitting..." : "Submit"}{" "}
                </button>
              </div>
              {error.remittanceId && (
                <div className="error-message">{error.remittanceId}</div>
              )}
              <div
                className="captcha"
                style={{
                  marginTop: "20px",
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <ReCaptchaV2
                  sitekey={env.REACT_APP_SITE_KEY}
                  onChange={handleToken}
                  onExpired={handleExpire}
                />
                {error.token && (
                  <div
                    style={{ textAlign: "center" }}
                    className="error-message"
                  >
                    {error.token}
                  </div>
                )}
              </div>
              {errorMessage && (
                <div
                  className="error-message"
                  style={{ textAlign: "center", marginTop: "20px" }}
                >
                  {errorMessage}
                </div>
              )}
            </div>
          </form>

          {remitDetails && remitDetails.success && (
            <>
              <div className="form__remit__detail__con">
                <div className="form__remit__detail">
                  Name: <b> {remitDetails.name}</b>{" "}
                </div>
                <div className="form__remit__detail">
                  Phone: <b>{remitDetails.phone}</b>{" "}
                </div>
                <div className="form__remit__detail">
                  Email: <b>{remitDetails.email}</b>{" "}
                </div>
                <div className="form__remit__detail">
                  Amount: <b>₦{amount}</b>{" "}
                </div>
              </div>

              <div className="form__remit__btnCon">
                <button
                  onClick={() =>
                    window.makePayment(
                      remitDetails.rrr,
                      remitDetails.transactionId
                    )
                  }
                >
                  Proceed Payment
                </button>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default RemittanceForm;
