import "./home.scss";
// import Modal from "../component/modal/Modal";
// import { useState } from "react";
// import ReCaptchaV2 from "react-google-recaptcha";
// import env from "react-dotenv";
import { Link } from "react-router-dom";
// import axios from "axios";
import logo from "../images/BIRS-logo.svg";
import taxIllust from "../images/svg/tax-illustration.svg";
import arrow from "../images/svg/arrow-right.svg";
import nounTax from "../images/svg/noun-tax.svg";
import nounInvoice from "../images/svg/noun-invoice.svg";

function Home() {
  // const [isModalOpen, setModalIsOpen] = useState(false);
  // const [path, setPath] = useState("/");
  // const [formToken, setFormToken] = useState("");
  // const [errorMessage, setErrorMessage] = useState("");
  // const [showSuccess, setShowSuccess] = useState(false);
  // const [loading, setLoading] = useState(false);
  // const [email, setEmail] = useState("");
  // const [error, setError] = useState({ email: "", token: "" });

  // const toggleModalCollection = () => {
  //   setModalIsOpen(!isModalOpen);
  //   setPath("/collection");
  // };

  // const toggleModal = () => {
  //   setModalIsOpen(!isModalOpen);
  // };

  // const handleToken = (token) => {
  //   setFormToken((currentForm) => {
  //     return { ...currentForm, token };
  //   });
  // };

  // const handleExpire = () => {
  //   setFormToken((currentForm) => {
  //     return { ...currentForm, token: null };
  //   });
  // };

  // const validate = () => {
  //   let emailError = "";
  //   let tokenError = "";
  //   if (!email.includes("@")) {
  //     emailError = "Invalid email";
  //   }
  //   if (!email) {
  //     emailError = " Email is required";
  //   }
  //   if (!formToken.token) {
  //     tokenError =
  //       "ReCaptcha is required, note! refresh page if ReCaptcha is not showing";
  //   }
  //   if (emailError || tokenError) {
  //     setError((currError) => {
  //       return { ...currError, email: emailError };
  //     });
  //     setError((currError) => {
  //       return { ...currError, token: tokenError };
  //     });
  //     return false;
  //   }
  //   return true;
  // };
  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   const checkValidate = validate();
  //   if (checkValidate) {
  //     setLoading(true);
  //     try {
  //       const res = await axios.post("/otp", { email });
  //       setLoading(false);
  //       if (res.data.success) {
  //         setShowSuccess(true);
  //       }
  //     } catch (err) {
  //       setLoading(false);
  //       setErrorMessage(err);
  //       setTimeout(() => {
  //         setErrorMessage("");
  //       }, 8000);
  //     }
  //   }
  // };

  return (
    <div>
      <section className="home" id="home">
        {/* {isModalOpen && (
          <Modal onRequestClose={toggleModal}>
            {showSuccess ? (
              <div>
                <p>
                  A OTP(One Time Password) has been sent to your Email which
                  would be used for Tax and Invoice payment
                </p>
                <br />
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  <Link style={{ color: "var(--primary-color)" }} to={path}>
                    continue {">>>"}
                  </Link>
                </div>
              </div>
            ) : (
              <>
                <h3 className="modal__title">
                  Fill this form and submit to access Tax Payment page
                </h3>

                <form onSubmit={handleSubmit}>
                  <div className="form__col__inputCon">
                    <label htmlFor="email">
                      Email<span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      style={{
                        border: !error.email
                          ? "1px solid black"
                          : "1px solid Var(--error-color)",
                      }}
                      id="email"
                      onChange={(e) => {
                        setEmail(e.target.value);

                        error.email &&
                          setError((err) => {
                            return { ...err, email: "" };
                          });
                      }}
                      type="text"
                    />
                    {error.email && (
                      <div className="error-message">{error.email}</div>
                    )}
                  </div>
                  <div className="captcha">
                    <ReCaptchaV2
                      sitekey={env.REACT_APP_SITE_KEY}
                      onChange={handleToken}
                      onExpired={handleExpire}
                    />
                    {error.token && (
                      <div className="error-message">{error.token}</div>
                    )}
                  </div>

                  <div className="form__col__btnCon">
                    <button>{loading ? "Submitting..." : "Submit"} </button>
                  </div>

                  {errorMessage && (
                    <div
                      className="error-message"
                      style={{ textAlign: "center", marginTop: "20px" }}
                    >
                      Something went wrong try again later!!!
                    </div>
                  )}
                </form>
              </>
            )}
          </Modal>
        )} */}
        <div className="home__main">
          <div className="home__main__left">
            <div>
              <img
                src={logo}
                className="home__main__left__logo"
                alt="BIRS logo"
              />
            </div>
            <div>
              <img src={taxIllust} alt="" className="home__main__left__img" />
            </div>
            <div className="home__main__left__details">
              <h1>Paying Your Tax Has Never Gotten Easier</h1>
              <p>
                BIRS is the revenue collecting arm of the Benue State Government
                saddled with the responsibility of collecting taxes and other
                revenues.
              </p>
            </div>
          </div>
          <div className="home__main__right">
            <div className="home__main__right__support">
              {/* Issues? <a href="support@birs.com.ng">support@birs.com.ng</a> */}
            </div>
            <div className="home__main__right__payment">
              <div className="home__main__right__payment__details">
                <img src={nounTax} alt="" />
                <h2>Tax/Non-Tax Payment</h2>
              </div>
              <div className="home__main__right__payment__link">
                <Link to="/collection">
                  <span>
                    Pay <img src={arrow} alt="" />
                  </span>
                </Link>
              </div>
            </div>
            <div className="home__main__right__payment">
              <div className="home__main__right__payment__details">
                <img src={nounInvoice} alt="" />
                <h2>Remittance Payment</h2>
              </div>
              <div className="home__main__right__payment__link">
                <Link to="/remittance-payment">
                  <span>
                    Remit <img src={arrow} alt="" />
                  </span>
                </Link>
              </div>
            </div>
            <div className="home__main__right__payment">
              <div className="home__main__right__payment__details">
                <img src={nounInvoice} alt="" />
                <h2>Invoice Payment</h2>
              </div>
              <div className="home__main__right__payment__link">
                <Link to="/invoice-payment">
                  <span>
                    Pay <img src={arrow} alt="" />
                  </span>
                </Link>
              </div>
            </div>
            <div className="home__main__right__payment">
              <div className="home__main__right__payment__details">
                <img src={nounInvoice} alt="" />
                <h2>Generate RRR</h2>
              </div>
              <div className="home__main__right__payment__link">
                <Link to="generate-rrr">
                  <span>
                    Generate <img src={arrow} alt="" />
                  </span>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="home__footer">
          <span>© 2022 BIRS, All Rights Reserved.</span>
          <span>Made by STETiS with ♥</span>
        </div>
      </section>
    </div>
  );
}

export default Home;
