import "./login.scss";
import logo from "../images/BIRS-logo.svg";
import { useEffect, useState } from "react";
import axios from "axios";
import ReactLoading from "react-loading";
import { useNavigate } from "react-router-dom";

const Login = () => {
  const [error, setError] = useState({});
  const [step, setStep] = useState(1);
  const [email, setEmail] = useState("");
  const [otp, setOtp] = useState("");
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const navigate = useNavigate();
  const OTP = localStorage.getItem("OTP");

  const validate = () => {
    let emailError = "";

    if (!email.includes("@")) {
      emailError = "Invalid email";
    }
    if (!email) {
      emailError = " Email is required";
    }

    if (emailError) {
      setError((currError) => {
        return { ...currError, email: emailError };
      });
      return false;
    }
    return true;
  };

  const validateOtp = () => {
    let otpError = "";

    if (!otp) {
      otpError = " otp is required";
    }
    if (otp && otp.length < 5) {
      otpError = " otp is invalid";
    }

    if (otpError) {
      setError((currError) => {
        return { ...currError, otp: otpError };
      });
      return false;
    }
    return true;
  };

  const handleSentOtp = async () => {
    const checkValidate = validate();
    if (checkValidate) {
      setLoading(true);
      try {
        const res = await axios.post("/otp", { email });
        setLoading(false);
        if (res.data.success) {
          setStep(2);
        } else {
          setErrorMessage(res.data.message);
          setTimeout(() => {
            setErrorMessage("");
          }, 8000);
        }
      } catch (err) {
        setLoading(false);
        setErrorMessage(err);
        setTimeout(() => {
          setErrorMessage("");
        }, 8000);
      }
    }
  };

  const handleValidateOtp = async () => {
    const checkValidate = validateOtp();
    if (checkValidate) {
      setLoading(true);
      try {
        const res = await axios.post("/validate_otp", { email, otp });
        setLoading(false);
        if (res.data.success) {
          localStorage.setItem("EMAIL", email);
          localStorage.setItem("OTP", otp);
          navigate("/");
        } else {
          setErrorMessage(res.data.message);
          setTimeout(() => {
            setErrorMessage("");
          }, 8000);
        }
      } catch (err) {
        setLoading(false);
        setErrorMessage(err);
        setTimeout(() => {
          setErrorMessage("");
        }, 8000);
      }
    }
  };

  const handleClick = () => {
    if (step === 1) {
      handleSentOtp();
    } else {
      handleValidateOtp();
      //   const checkValidate = validateOtp();
      //   if (checkValidate) {
      //     localStorage.setItem("OTP", otp);
      //     navigate("/");
      //     // console.log("correct", otp);
      //   }
    }
  };

  useEffect(() => {
    if (OTP) {
      navigate("/");
    }
  }, [OTP, navigate]);

  return (
    <div className="login">
      <div className="login__header">
        <img src={logo} alt="" />
      </div>

      <div className="login__main">
        <h1 className="login__main__title">Login</h1>

        <div className="login__main__inputs">
          {step === 1 && (
            <div className="login__main__inputs__item">
              <label htmlFor="email">Enter a Valid Email</label>
              <input
                // placeholder="enter a valid email address"
                className="login__main__inputs__item__input"
                style={{
                  border: !error.email
                    ? "1px solid black"
                    : "1px solid var(--error-color)",
                }}
                id="email"
                onChange={(e) => {
                  setEmail(e.target.value);
                  if (error.email) {
                    setError({});
                  }
                }}
                type="text"
                value={email}
              />
              {error.email && (
                <div className="error-message">{error.email}</div>
              )}
            </div>
          )}

          {step === 2 && (
            <div className="login__main__inputs__item">
              <p className="login__main__inputs__item__disc">
                A OTP (One Time Password) has been sent to your Email, which
                will be used to continue the login process
              </p>
              <label htmlFor="otp">One Time Password (OTP) </label>
              <input
                style={{
                  border: !error.otp
                    ? "1px solid black"
                    : "1px solid var(--error-color)",
                }}
                id="otp"
                onChange={(e) => {
                  setOtp(e.target.value);
                  if (error.otp) {
                    setError({});
                  }
                }}
                type="text"
                value={otp}
              />
              {error.otp && <div className="error-message">{error.otp}</div>}
            </div>
          )}
        </div>

        <button onClick={handleClick} className="login__main__btn">
          {" "}
          {loading ? (
            <ReactLoading
              type="spin"
              color="white"
              height={"28px"}
              width={"28px"}
            />
          ) : step === 1 ? (
            "Continue"
          ) : (
            "Login"
          )}{" "}
        </button>

        {errorMessage && (
          <div
            className="error-message"
            style={{ textAlign: "center", marginTop: "20px" }}
          >
            Something went wrong try again later!!!
          </div>
        )}
      </div>
    </div>
  );
};

export default Login;
