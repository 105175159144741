import FormNav from "../formNav/FormNav";
import "./paymentFailure.scss";
import failureImg from "../images/svg/Group 58.svg";
import { Link } from "react-router-dom";

function PaymentFailure() {
  return (
    <div className="paymentFailure">
      <FormNav />
      <div className="paymentFailure__main">
        <img src={failureImg} alt="" />
        <p className="paymentFailure__main__details">Payment Failed</p>
        <div className="paymentFailure__main__btnCon">
          <Link to="/">
            <button>Try Again</button>
          </Link>
          <button>Contact Support</button>
        </div>
      </div>
    </div>
  );
}

export default PaymentFailure;
