// import img from "./images/BIRS-logo.svg";
import "./app.scss";
import CollectionForm from "./collectionForm/CollectionForm";
import RemittanceForm from "./remittanceForm/RemittanceForm";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./home/Home";
import axios from "axios";
import Notfound from "./notFound/Notfound";
import PaymentSuccess from "./paymentSuccess/PaymentSuccess";
import PaymentFailure from "./paymentFailure/PaymentFailure";
import GenerateRRR from "./generateRRR/GenerateRRR";
import InvoicePayment from "./invoicePayment/InvoicePayment";
import Login from "./login/Login";
import MainLayout from "./layouts/MainLayout";

function App() {
  axios.defaults.baseURL = "https://igrportal.com:8643/benue/remita";

  return (
    <Router>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/" element={<MainLayout />}>
          <Route index element={<Home />} />
          <Route path="collection" element={<CollectionForm />} />
          <Route path="generate-rrr" element={<GenerateRRR />} />
          <Route path="invoice-payment" element={<InvoicePayment />} />
          <Route path="remittance-payment" element={<RemittanceForm />} />
          <Route path="payment-success" element={<PaymentSuccess />} />
          <Route path="payment-failure" element={<PaymentFailure />} />
        </Route>
        <Route path="*" element={<Notfound />} />
      </Routes>
    </Router>
  );
}

export default App;
