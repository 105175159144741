import "./generateRRR.scss";
import { useEffect } from "react";
import { useState } from "react";
import Select from "react-select";
import axios from "axios";
import FormNav from "../formNav/FormNav";
import Modal from "../component/modal/Modal";

function GenerateRRR() {
  const [revenueItems, setRevenueItems] = useState([]);
  const [itemsOpton, setItemsOpton] = useState([]);
  const [agencySelect, setAgencySelect] = useState([]);
  const [error, setError] = useState({});
  const [revenueItem, setRevenueItem] = useState("");
  const [agency, setagency] = useState("");
  const [amount, setAmount] = useState("");
  const [payerName, setPayerName] = useState("");
  const [payerId, setpayerId] = useState("");
  //   const [payerEmail, setPayerEmail] = useState("");
  const [payerPhone, setpayerPhone] = useState("");
  const [agencies, setAgencies] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  //   const [amountCheck, setAmountCheck] = useState(0);
  const [isModalOpen, setModalIsOpen] = useState(false);
  const [rrr, setrrr] = useState("");

  const formatNo = (amount) => {
    const stringConvert = JSON.stringify(amount);
    const formatedAmount = stringConvert.replace(/[^0-9.]/g, "");

    setAmount(formatedAmount);
  };
  const OTP = localStorage.getItem("OTP");
  const EMAIL = localStorage.getItem("EMAIL");
  useEffect(() => {
    if (agency) {
      agencies &&
        agencies.forEach((ag) => {
          if (ag.code === agency.value) {
            setRevenueItems(ag.items);
          }
        });

      setRevenueItem("");
    }
  }, [agency, agencies]);

  useEffect(() => {
    if (revenueItems) {
      let agencyItems = [];
      revenueItems.forEach((item) => {
        agencyItems.push({
          value: item.code,
          label: item.name,
        });
      });
      setItemsOpton(agencyItems);
    }
  }, [revenueItems]);

  useEffect(() => {
    if (revenueItem) {
      revenueItems &&
        revenueItems.forEach((item) => {
          if (item.code === revenueItem.value) {
            formatNo(item.rate);
            // setAmountCheck(item.rate);
          }
        });
    }
  }, [revenueItem, revenueItems]);

  useEffect(() => {
    const options = [];
    agencies &&
      agencies.forEach((agency) => {
        options.push({ value: agency.code, label: agency.name });
      });

    setAgencySelect(options);
  }, [agencies]);

  useEffect(() => {
    window.scroll(0, 0);

    const fetchAgency = async () => {
      const res = await axios.get("/subheads");
      setAgencies(res.data.data);
    };
    fetchAgency();
  }, []);

  const validate = () => {
    let payerNameError = "";
    let agencyError = "";
    let revenueItemError = "";
    let amountError = "";

    if (!payerName) {
      payerNameError = "name is required";
    }
    if (!amount) {
      amountError = "amount is required";
    }
    if (amount && parseInt(amount) < 101) {
      amountError = "amount must be more than ₦100";
    }
    if (!agency) {
      agencyError = "Agency is required";
    }
    if (!revenueItem) {
      revenueItemError = "Revenue subhead is required";
    }
    if (payerNameError || agencyError || revenueItemError || amountError) {
      setError((currError) => {
        return { ...currError, payerName: payerNameError };
      });
      setError((currError) => {
        return { ...currError, amount: amountError };
      });
      setError((currError) => {
        return { ...currError, agency: agencyError };
      });
      setError((currError) => {
        return { ...currError, revenueItem: revenueItemError };
      });

      return false;
    }
    return true;
  };

  const SelectStyles = {
    control: (provided, state) => {
      const border = state.selectProps.error
        ? "1px solid var(--error-color)"
        : "1px solid black";

      const paddingLeft = "10px";

      return {
        ...provided,
        paddingLeft,
        border,
        borderRadius: "0px",
        boxShadow: "none",
        height: "40px",
        ":hover": {
          border: "1px solid var(--secondary-color)",
        },
        ":focus": {
          border: "1px solid var(--secondary-color)",
        },
      };
    },
    container: (provided) => {
      return {
        ...provided,

        ":focus": {
          border: "1px solid var(--secondary-color)",
        },
      };
    },
    singleValue: (provided, state) => {
      const opacity = state.selectProps.isDisabled ? 0.5 : 1;
      const transition = "opacity 300ms";

      return { ...provided, opacity, transition, marginTop: "2px" };
    },

    valueContainer: (provided) => {
      return { ...provided, height: "40px" };
    },

    input: (provided) => {
      return { ...provided, margin: "0 2px", paddingBottom: "0" };
    },
    placeholder: (provided) => {
      return { ...provided, marginTop: "2px" };
    },
  };

  const handleAgencyChange = (agency) => {
    setagency(agency);
  };

  const handleItemChange = (item) => {
    setRevenueItem(item);
  };

  //   const clearData = () => {
  //     setAmount("");
  //     setPayerEmail("");
  //     setPayerName("");
  //     setRate("");
  //     setagency("");
  //     setRevenueItem("");
  //     setpayerPhone("");
  //     setpayerId("");
  //   };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const checkValidate = validate();
    if (checkValidate) {
      setError({});
      setLoading(true);
      const data = {
        otp: OTP ? OTP : "",
        payerEmail: EMAIL ? EMAIL : "",
        amount,
        payerName,
        payerPhone,
        payerId,
        agency: agency.label,
        item: revenueItem.label,
        agencyCode: agency.value,
        itemCode: revenueItem.value,
      };

      try {
        const res = await axios.post("/tax", data);
        setLoading(false);
        if (res.data.success) {
          setModalIsOpen(true);
          setrrr(res.data.rrr);
        } else {
          setErrorMessage(res.data.message);

          setTimeout(() => {
            setErrorMessage("");
          }, 8000);
        }
      } catch (err) {
        setErrorMessage("Something went wrong try again later!!!");
        setTimeout(() => {
          setErrorMessage("");
        }, 8000);
        setLoading(false);
      }
    }
  };

  const toggleModal = () => {
    setModalIsOpen(!isModalOpen);
    // clearData();
  };
  return (
    <div className="form__col__con">
      <FormNav />
      {isModalOpen && (
        <Modal onRequestClose={toggleModal}>
          <div style={{ textAlign: "center" }}>
            <p>Name: {payerName}</p>
            <p>Remita Retrieval Reference (RRR)</p>
            <h1 style={{ color: "var(--primary-color)" }}>{rrr}</h1>
          </div>
        </Modal>
      )}
      <form className="form__col" onSubmit={handleSubmit}>
        <h1 className="form__col__header">
          Generate RRR for Tax & Non Tax Payments{" "}
        </h1>

        <div className="form__col__inputWrapper">
          <div className="form__col__inputWrapper__con">
            <div className="form__col__inputCon">
              <label htmlFor="payerName">
                Name<span style={{ color: "red" }}>*</span>
              </label>
              <input
                value={payerName}
                style={{
                  border: !error.payerName
                    ? "1px solid black"
                    : "1px solid var(--error-color)",
                }}
                id="payerName"
                onChange={(e) => setPayerName(e.target.value)}
                type="text"
              />
              {error.payerName && (
                <div className="error-message">{error.payerName}</div>
              )}
            </div>
            <div className="form__col__inputCon">
              <label htmlFor="payerId">Payer Id</label>
              <input
                value={payerId}
                id="payerId"
                onChange={(e) => setpayerId(e.target.value)}
                type="text"
              />
            </div>

            <div className="form__col__inputCon">
              <label htmlFor="agency">
                Agency<span style={{ color: "red" }}>*</span>
              </label>
              <Select
                styles={SelectStyles}
                options={agencySelect}
                isClearable
                isSearchable
                placeholder={<div>select agency...</div>}
                onChange={handleAgencyChange}
                value={agency}
                error={error.agency ? true : false}
              />
              {error.agency && (
                <div className="error-message">{error.agency}</div>
              )}
            </div>
            <div className="form__col__inputCon">
              <label htmlFor="revenueSubhead">
                Revenue Subhead
                <span style={{ color: "red" }}>*</span>
              </label>
              <Select
                styles={SelectStyles}
                options={itemsOpton}
                isClearable
                isSearchable
                placeholder={<div>select subhead...</div>}
                onChange={handleItemChange}
                value={revenueItem}
                error={error.revenueItem ? true : false}
              />
              {error.revenueItem && (
                <div className="error-message">{error.revenueItem}</div>
              )}
            </div>
            <div className="form__col__inputCon">
              <label htmlFor="number">Phone Number</label>
              <input
                value={payerPhone}
                id="number"
                onChange={(e) => setpayerPhone(e.target.value)}
                type="text"
              />
            </div>
            <div className="form__col__inputCon">
              <label htmlFor="amount">
                Amount<span style={{ color: "red" }}>*</span>
              </label>
              <div className="form__col__inputCon__amount">
                <input
                  style={{
                    paddingLeft: "40px",
                    width: "100%",
                    border: !error.amount
                      ? "1px solid black"
                      : "1px solid var(--error-color)",
                  }}
                  value={amount}
                  onChange={(e) => formatNo(e.target.value)}
                  id="amount"
                  type="text"
                />
                {/* disabled={amountCheck > 100 ? true : false} */}
                <span>
                  <p>₦</p>
                </span>
              </div>
              {error.amount && (
                <div className="error-message">{error.amount}</div>
              )}
            </div>
          </div>

          <div className="form__col__btnCon">
            <button type="submit">
              {loading ? "Generating RRR..." : "Generate RRR"}{" "}
            </button>
          </div>
          {errorMessage && (
            <div
              className="error-message"
              style={{ textAlign: "center", marginTop: "20px" }}
            >
              {errorMessage}
            </div>
          )}
        </div>
      </form>
    </div>
  );
}

export default GenerateRRR;
