import { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";

const MainLayout = () => {
  const navigate = useNavigate();
  const OTP = localStorage.getItem("OTP");

  useEffect(() => {
    if (!OTP) {
      navigate("/login");
    }
  }, [OTP, navigate]);

  return (
    <div>
      <Outlet />
    </div>
  );
};

export default MainLayout;
