import { useEffect } from "react";
import "./modal.scss";
import closeIcon from "../../images/close-round-line.svg";

function Modal({ onRequestClose, children }) {
  useEffect(() => {
    function onKeyDown(event) {
      if (event.keyCode === 27) {
        // Close the modal when the Escape key is pressed
        onRequestClose();
      }
    }

    // Prevent scolling
    document.body.style.overflow = "hidden";
    document.addEventListener("keydown", onKeyDown);

    // Clear things up when unmounting this component
    return () => {
      document.body.style.overflow = "visible";
      document.removeEventListener("keydown", onKeyDown);
    };
  });
  return (
    <div className="modal__backdrop">
      <div className="modal__container">
        <div className="modal__img__con">
          <img
            className="modal__img"
            src={closeIcon}
            alt=""
            onClick={onRequestClose}
          />
        </div>
        {children}
        {/* <h3 className="modal__title">I'm a modal!</h3>
        <p>
          When this modal is open, we disable scrolling the <code>body</code>{" "}
          using <code>overflow: hidden</code>. This allows users to scroll the
          modal without losing their position on the page.
        </p>
        <p>
          To close this modal, press the button below or use the Escape key on
          desktop.
        </p>
        <button type="button"
          Close this modal
        </button> */}
      </div>
    </div>
  );
}

export default Modal;
