import FormNav from "../formNav/FormNav";
import "./notFound.scss";
import notFoundImg from "../images/svg/Group 71.svg";
import { Link } from "react-router-dom";

function Notfound() {
  return (
    <div className="notFound">
      <FormNav />
      <img className="notFound__img" src={notFoundImg} alt="Page Not Found" />
      <h1 className="notFound__header">Page Not Found</h1>
      <p className="notFound__details">
        We are sorry, the page you requested could not be found. Please go to
        the homepage.
      </p>
      <Link to="/">
        <button className="notFound__btn">Go Home</button>
      </Link>
    </div>
  );
}

export default Notfound;
