import "./formNav.scss";
import logo from "../images/BIRS-logo.svg";
import { Link } from "react-router-dom";

function FormNav() {
  return (
    <nav className="formNav">
      <div className="formNav__logo__con">
        <Link to="/">
          <img src={logo} alt="logo" className="formNav__logo" />
        </Link>
      </div>
    </nav>
  );
}

export default FormNav;
