import FormNav from "../formNav/FormNav";
import "./paymentSuccess.scss";
import successImg from "../images/svg/Group 56.svg";
import { Link } from "react-router-dom";

function PaymentSuccess() {
  return (
    <div className="paymentSuccess">
      <FormNav />
      <div className="paymentSuccess__main">
        <img src={successImg} alt="" />
        <p className="paymentSuccess__main__details">Payment Successful</p>
        <div className="paymentSuccess__main__btnCon">
          <Link to="/">
            <button>Download Receipt</button>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default PaymentSuccess;
